import React, { useState } from "react";
import { MdOutlineAttachFile } from "react-icons/md";

import woods from "../../assets/woods/images";
import images from "../../assets/carpentry/images";

import SwiperCustom from "../SwiperCustom/SwiperCustom";
import Email from "../../api/services/Email";
import Select from "../Form/Select";
import WoodCard from "../Form/WoodCard";
import { TYPES } from "../../constants/global";
import Accordion from "../Form/Accordion";
import SubmitButton from "../Form/SubmitButton";

const TableOtherCarp = (props) => {
	const [format, setFormat] = useState(null);
	const [otherFormat, setOtherFormat] = useState(null);

	const [formData, setFormData] = useState({
		type: TYPES.TABLES_OTHER_CARPENTRY,
		email: props.email,
	});
	const [model, setModel] = useState({});

	const [errors, setErrors] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [swiper, setSwiper] = useState({});

	const dimensions = [
		{value: "1m X 1m", label: "1m X 1m"},
		{value: "2m X 1m", label: "2m X 1m"},
		{value: "3m X 1m", label: "3m X 1m"},
		{value: "4m X 1m", label: "4m X 1m"},
		{value: "5m X 1m", label: "5m X 1m"},
		{value: "2m X 2m", label: "2m X 2m"},
		{value: "3m X 2m", label: "3m X 2m"},
		{value: "4m X 2m", label: "4m X 2m"},
		{value: "5m X 2m", label: "5m X 2m"},
		{value: "Circle", label: "Circle"},
		{value: "Other shapes", label: "Other shapes"},
	];

	const edges = [
		{value: "Straight", label: "Straight"},
		{value: "Live Edge", label: "Live Edge"},
	];

	const formats = [
		{value: "Circle", label: "Circle"},
		{value: "Rectangle", label: "Rectangle"},
		{value: "Square", label: "Square"},
		{value: "Other", label: "Other"},
	];

	const handleSwiper = (swiper) => {
		setSwiper(swiper)
	}

	const handleModelData = (value, field) => {
		setModel({ ...model, [field]: value });
	}

	const submitForm = async () => {
		setErrors([]);
		setIsLoading(true);
		let jsonModel = "";
		if (otherFormat) {
			jsonModel = {...model, "format": otherFormat};
		} else {
			jsonModel = {...model, "format": format};
		}
		const response = await Email.send( {...formData, model: JSON.stringify(jsonModel)});
		if(response.status === 200) {
			props.submit();
			setIsLoading(false);
		}
		else {
			setErrors(response.response.data.reduce(((result, current) => Object.assign(result, current)), {}));
			setIsLoading(false);
		}
	};

	return (
		<div className="flex flex-col lg:flex-row items-start justify-between h-full w-full bg-white md:py-10 lg:pb-0">
			<div className="w-full lg:w-3/5 max-h-96 md:max-h-md lg:max-h-3xl xl:max-h-5xl flex flex-col h-full">
				<SwiperCustom images={images} getSwiper={ (e) => { handleSwiper(e) } } />
				<p className="py-5 px-5 lg:px-10 xl:px-20 text-2xl xl:text-3xl"></p>
			</div>
			<div className="w-full lg:w-2/5 h-full lg:max-h-full flex flex-col pb-8 md:pb-14 px-5 lg:px-10 xl:pl-10 xl:pr-16 gap-y-6 2xl:gap-y-8 lg:overflow-auto mt-5 md:mt-0">

				{/* DIMENSIONS */}
				<Accordion label={"Dimensions"} error={errors?.dimension} childrenHasChanges={model.dimension} swiper={swiper}>
					<Select options={dimensions} placeholder={"Dimension"} onChange={(e) => { handleModelData(e.value, "dimension") }} />
				</Accordion>

				{/* WOODS */}
				<Accordion label={"Wood"} error={errors?.wood} childrenHasChanges={model.wood} prevAccordionValue={model.dimension} swiper={swiper}>
					<div className="grid grid-cols-4 lg:grid-cols-3 items-center justify-center w-full gap-y-3 lg:gap-y-5">
						{woods.map((wood, index) => (
							<WoodCard key={index} wood={wood} selectedWood={model.wood} onChange={(e) => { handleModelData(e.value, "wood") }} />
						))}
					</div>
				</Accordion>

				{/* EDGE */}
				<Accordion label={"Edge"} error={errors?.edge} childrenHasChanges={model.edge} prevAccordionValue={model.wood} swiper={swiper}>
					<Select options={edges} placeholder={"Edge"} onChange={(e) => { handleModelData(e.value, "edge") }} />
				</Accordion>

				{/* FORMAT */}
				<Accordion label={"Format"} error={errors?.format} childrenHasChanges={format === "Other" ? null : format} prevAccordionValue={model.edge} swiper={swiper}>
					<div className="w-full">
						<Select options={formats} placeholder={"Format"} onChange={(e) => { setFormat(e.value); setOtherFormat(null) }} />
						{format === "Other" && (
							<input
								type="text"
								placeholder="Format..."
								className="bg-white p-3 xl:p-4 2xl:p-5 w-full rounded-xl text-lg lg:text-xl 2xl:text-2xl shadow-custom focus:outline-none mt-8 lg:mt-5"
								onChange={(e) => setOtherFormat(e.target.value)}
							/>
						)}
					</div>
				</Accordion>
				
				{/* SPECIFICATIONS */}
				<Accordion label={"Specifications"} error={errors?.specifications} prevAccordionValue={format === "Other" ? otherFormat : format} swiper={swiper}>
					<div className="w-full mt-5 md:mb-10 flex flex-col gap-y-5">
						<textarea
							onChange={(e) => handleModelData(e.target.value, "specifications")}
							value={model?.specifications}
							className="shadow-custom placeholder:text-black/50 w-full rounded-2xl p-5 placeholder:text-xl text-xl h-52"
							placeholder="Send us a reference..."
						/>
						<div className="flex items-center gap-x-5">
							<label
								htmlFor="file"
								className="text-lg md:text-xl lg:text-2xl flex items-center text-black/30 hover:cursor-pointer"
							>
								<MdOutlineAttachFile />
								<p className="text-black/50 text-lg lg:text-xl ml-2">{formData?.file_image ? formData?.file_image.name : 'Attach your image'}</p>
							</label>
							<input
								onChange={(e) => { setFormData({...formData, "file_image": e.target.files[0]}) }}
								type="file"
								id="file"
								className="hidden"
							/>
						</div>
					</div>
				</Accordion>
				
				{ /* SUBMIT */ }
				<div className="flex items-center justify-center">
					<SubmitButton isLoading={isLoading} onClick={(e) => {submitForm(e)}} />
				</div>
			</div>
		</div>
	);
};

export default TableOtherCarp;
