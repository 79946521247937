import React, { useEffect, useState } from "react";
import Homepage from './components/Homepage/index.js';
import InitialForm from "./components/InitialForm/index.js";
//import StartingScreen from "./components/StartingScreen/index.js";
import TableOtherCarp from "./components/TableOtherCarp/index.js";
import TinyHouses from "./components/TinyHouses/index.js";
import TreeHouses from "./components/TreeHouses/index.js";
import Sculptures from "./components/Sculptures/index.js";
import Modal from './components/Modal/index.js';
import Navbar from './components/Navbar/index.js';

function App() {

  useEffect(() => {
    document.title = "Madeiguincho";
  }, []);

  const [state, setState] = useState(0);
  const [step, setStep] = useState(null);
  const [submitModal, setSubmitModal] = useState(false);

  //NAVBAR FUNCTION
  const backToStart = () => {
    setState(0);
    setStep(null);
  }

  // INITIAL SCREEN BEFORE ANY PICKS
  const [type, setType] = useState('');
  //const [writeType, setWriteType] = useState('');
  const handleType = (data) => {
    setType(data);
    setState(1);
  }

  /*useEffect(() => {
    switch (type) {
      case 1:
        setWriteType('Table & Other Carpentry');
        break;
      case 2:
        setWriteType('Tiny House');
        break;
      case 3:
        setWriteType('Tree House');
        break;
      case 4:
        setWriteType('Sculpture');
        break;
      default:
        setWriteType('');
        break;
    }
  }, [type])*/

  //INITIAL FORM
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const handleInitialForm = (firstName, lastName, email) => {
    setFirstName(firstName);
    setLastName(lastName);
    setEmail(email);
    setStep(1);
  }

  //STARTING SCREEN
  /*const handleStartingScreen = () => {
    setStep(1);
  }*/

  const submitData = () => {
    setSubmitModal(true);
  }

  const hideModal = () => {
    setSubmitModal(false);
  }

  return (
    <div className="App">
      <div className={`${ (state && step) ? 'bg-white' : 'bg-background' } flex flex-col min-h-screen bg-cover`}>
        <Navbar stateType={state} onClick={() => backToStart()} />
        <div className="md:h-[calc(100vh-165px)]">
          {state === 0 &&
            <Homepage onType={handleType} />
          }
          {/* TODO: Turn into modal so it renders above the homepage */}
          {state === 1 && step === null &&
            <InitialForm onInitalForm={handleInitialForm} />
          }
          {/*state === 1 && step === 0 &&
            <StartingScreen onStartingScreen={handleStartingScreen} title={writeType} />
        */}
          {state === 1 && step === 1 && type === 1 &&
            <TableOtherCarp submit={submitData} email={email} />
          }
          {state === 1 && step === 1 && type === 2 &&
            <TinyHouses submit={submitData} email={email} />
          }
          {state === 1 && step === 1 && type === 3 &&
            <TreeHouses submit={submitData} email={email} />
          }
          {state === 1 && step === 1 && type === 4 &&
            <Sculptures submit={submitData} email={email} />
          }
        </div>
      </div>
      {submitModal &&
        <Modal hideModal={hideModal} backToStart={() => backToStart()} />
      }
    </div>
  );
}

export default App;
