import React, { useEffect, useState } from "react";
import { MdOutlineAttachFile } from "react-icons/md";

import Email from "../../api/services/Email";
import SwiperCustom from "../SwiperCustom/SwiperCustom";
import images from "../../assets/tinyHouses/images";

import WoodCard from "../Form/WoodCard";
import woods from "../../assets/woods/images";
import { TYPES } from "../../constants/global";
import Select from "../Form/Select";
import Accordion from "../Form/Accordion";
import CheckboxCard from "../Form/CheckboxCard";
import SubmitButton from "../Form/SubmitButton";

const TinyHouses = (props) => {
	const [value, setValue] = useState(null);
	const [offGrid, setOffGrid] = useState([]);
	const [selectedAddons, setSelectedAddons] = useState([]);

	const [formData, setFormData] = useState({
		type: TYPES.TINY_HOUSES,
		email: props.email,
	});
	const [model, setModel] = useState({});

	const [errors, setErrors] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [swiperImages, setSwiperImages] = useState(images.trailer);

	const dimensions = [
		{value: "4m", label: "4m"},
		{value: "5m", label: "5m"},
		{value: "7m", label: "7m"}
	];

	const sleepingAreas = [
		{value: "1 Double bed", label: "1 Double bed"},
		{value: "1 Double bed + sofa bed", label: "1 Double bed + sofa bed"},
		{value: "2 Double beds", label: "2 Double beds"},
		{value: "Fully customized", label: "Fully customized"},
	];

	const roofs = [
		{value: "Shed roof", label: "Shed roof"},
		{value: "Flat roof", label: "Flat roof"},
		{value: "Open gable roof", label: "Open gable roof"},
		{value: "Hip roof", label: "Hip roof"},
	];

	const addons = [
		"Cork Insulation",
		"Access Stairs",
		"Awning",
		"Salamandra",
		"Mosquito Nets",
		"Shutters",
		"Burnt Exterior Finish",
		"Sky Light Closed",
		"Open/Access Skylight",
	];

	const handleModelData = (value, field) => {
		setModel({ ...model, [field]: value });
	}

	const handleOffGridOptions = (value) => {
		if (offGrid.includes("None of the above")) {
			setOffGrid([]);
		}

		offGrid.includes(value)
		? setOffGrid((prevOptions) => prevOptions.filter((option) => option !== value))
		: setOffGrid((prevOptions) => [...prevOptions, value])
	}

	const handleAddonsOptions = (value) => {
		selectedAddons.includes(value)
		? setSelectedAddons((prevOptions) => prevOptions.filter((option) => option !== value))
		: setSelectedAddons((prevOptions) => [...prevOptions, value]);
	}

	const submitForm = async () => {
		setErrors([]);
		setIsLoading(true);
		let jsonModel = {...model, "offGridSystems": offGrid, "addOns": selectedAddons, "price": value};		
		const response = await Email.send( {...formData, model: JSON.stringify(jsonModel)});
		if(response.status === 200) {
			props.submit();
			setIsLoading(false);
		}
		else {
			setErrors(response.response.data.reduce(((result, current) => Object.assign(result, current)), {}));
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (model.trailer === "4m") {
			setValue('53k');
		} else if (model.trailer === "5m") {
			setValue('61k');
		} else if (model.trailer === "7m") {
			setValue('68k');
		} else {
			setValue(null);
		}
	}, [model.trailer])
	

	return (
		<div className="flex flex-col lg:flex-row items-start justify-between h-full w-full bg-white md:py-10 lg:pb-0">
			<div className="w-full lg:w-3/5 max-h-96 md:max-h-md lg:max-h-3xl xl:max-h-5xl flex flex-col h-full">
				<SwiperCustom images={swiperImages} />
				<p className="py-5 px-5 lg:px-10 xl:px-20 text-2xl xl:text-3xl">{ value ? `From € ${value}` : 'Start by choosing the trailer size' }</p>
			</div>

			<div className="w-full lg:w-2/5 h-full lg:max-h-full flex flex-col pb-8 md:pb-14 px-5 lg:px-10 xl:pl-10 xl:pr-16 gap-y-6 2xl:gap-y-8 lg:overflow-auto mt-5 md:mt-0">

				{/* TRAILER */}
				<Accordion label={"Trailer"} error={errors?.trailer} childrenHasChanges={model.trailer} onClick={ () => {setSwiperImages(images.trailer) }}>
					<Select options={dimensions} placeholder={"Dimension"} onChange={(e) => { handleModelData(e.value, "trailer") }} />
				</Accordion>

				{/* SLEEPING */}
				<Accordion label={"Sleeping Area"} error={errors?.sleepingArea} childrenHasChanges={model.sleepingArea} prevAccordionValue={model.trailer} onClick={ () => {setSwiperImages(images.sleeping_area) }}>
					<Select options={sleepingAreas} placeholder={"Sleeping Areas"} onChange={(e) => { handleModelData(e.value, "sleepingArea") }} />
				</Accordion>

				{/* KITCHEN */}
				<Accordion label={"Kitchen Module"} error={errors?.kitchenModule} childrenHasChanges={model.kitchenModule} prevAccordionValue={model.sleepingArea} onClick={ () => {setSwiperImages(images.kitchen) }}>
					<div className="w-full grid grid-cols-2 items-center gap-x-5">
						<CheckboxCard onClick={() => handleModelData("Yes", "kitchenModule")} label={"Yes"} value={model.kitchenModule} />
						<CheckboxCard onClick={() => handleModelData("No", "kitchenModule")} label={"No"} value={model.kitchenModule} />
					</div>
				</Accordion>

				{/* BATHROOM */}
				<Accordion label={"Bathroom"} error={errors?.bathroom} childrenHasChanges={model.bathroom} prevAccordionValue={model.kitchenModule} onClick={ () => {setSwiperImages(images.bathroom) }}>
					<div className="w-full grid grid-cols-2 items-center gap-x-5">
						<CheckboxCard onClick={() => handleModelData("Yes", "bathroom")} label={"Yes"} value={model.bathroom} />
						<CheckboxCard onClick={() => handleModelData("No", "bathroom")} label={"No"} value={model.bathroom} />
					</div>
				</Accordion>

				{/* OFF GRID */}
				<Accordion label={"Off Grid Systems"} error={errors?.offGridSystems} childrenHasChanges={offGrid.includes("None of the above")} prevAccordionValue={model.bathroom} onClick={ () => {setSwiperImages(images.offgrid_systems) }}>
					<div className="flex flex-col items-center gap-y-3 w-full">
						<CheckboxCard onClick={() => { handleOffGridOptions("Solar off-grid system") }}
							label={"Solar off-grid system"} value={offGrid}
						/>
						<CheckboxCard onClick={() => { handleOffGridOptions("Water off-grid systems") }}
							label={"Water off-grid systems"} value={offGrid}
						/>
						<CheckboxCard onClick={() => { handleOffGridOptions("Hydroponic herbs production") }}
							label={"Hydroponic herbs production"} value={offGrid}
						/>
						<CheckboxCard onClick={() => {
							offGrid.includes("None of the above")
								? setOffGrid([])
								: setOffGrid(["None of the above"]);
							}}
							label={"None of the above"} value={offGrid}
						/>
					</div>
				</Accordion>	
				
				{/* ADDONS */}
				<Accordion label={"Add ons"} error={errors?.addOns} prevAccordionValue={offGrid.length == 0 ? null : offGrid} onClick={ () => {setSwiperImages(images.addons) }}>
					<div className="flex flex-col items-center gap-y-3 w-full">
						{addons.map((addon, index) => (
							<CheckboxCard key={index} onClick={() => { handleAddonsOptions(addon) }} label={addon} value={selectedAddons} />	
						))}
					</div>
				</Accordion>
				
				{/* WOODS */}
				<Accordion label={"Wood Countertop"} error={errors?.woodCountertop} childrenHasChanges={model.woodCountertop} prevAccordionValue={selectedAddons.length == 0 ? null : selectedAddons} onClick={ () => {setSwiperImages(images.wood_countertop) }}>
					<div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-3 items-center justify-center w-full gap-y-3 lg:gap-y-5">
						{woods.map((wood, index) => (
							<WoodCard key={index} wood={wood} selectedWood={model.woodCountertop} onChange={(e) => { handleModelData(e.value, "woodCountertop") }} />
						))}
					</div>
				</Accordion>
				
				{/* ROOFS */}
				<Accordion label={"Roof types"} error={errors?.roofTypes} childrenHasChanges={model.roofTypes} prevAccordionValue={model.woodCountertop} onClick={ () => {setSwiperImages(images.roof_types) }}>
					<Select options={roofs} placeholder={"Roof Type"} onChange={(e) => { handleModelData(e.value, "roofTypes") }} />
				</Accordion>

				{/* SPECIFICATIONS */}
				<Accordion label={"Specifications"} error={errors?.specifications} prevAccordionValue={model.roofTypes}>
					<div className="w-full mt-5 md:mb-10 flex flex-col gap-y-5">
						<textarea
							onChange={(e) => handleModelData(e.target.value, "specifications")}
							value={model?.specifications}
							className="shadow-custom placeholder:text-black/50 w-full rounded-2xl p-5 placeholder:text-xl text-xl h-52"
							placeholder="Send us a reference..."
						/>
						<div className="flex items-center gap-x-5">
							<label
								htmlFor="file"
								className="text-lg md:text-xl lg:text-2xl flex items-center text-black/30 hover:cursor-pointer"
							>
								<MdOutlineAttachFile />
								<p className="text-black/50 text-lg lg:text-xl ml-2">{formData?.file_image ? formData?.file_image.name : 'Attach your image'}</p>
							</label>
							<input
								onChange={(e) => { setFormData({...formData, "file_image": e.target.files[0]}) }}
								type="file"
								id="file"
								className="hidden"
							/>
						</div>
					</div>
				</Accordion>

				{ /* SUBMIT */ }
				<div className="flex items-center justify-center">
					<SubmitButton isLoading={isLoading} onClick={(e) => {submitForm(e)}} />
				</div>
			</div>
		</div>
	);
};

export default TinyHouses;
