import React, { useState } from "react";
import {
	MdOutlineAttachFile,
} from "react-icons/md";

import SwiperCustom from "../SwiperCustom/SwiperCustom";
import images from "../../assets/sculptures/images";
import materials from "../../assets/woods/materials";

import Email from "../../api/services/Email";
import Select from "../Form/Select";
import WoodCard from "../Form/WoodCard";
import { TYPES } from "../../constants/global";
import Accordion from "../Form/Accordion";
import SubmitButton from "../Form/SubmitButton";

const Sculptures = (props) => {
	
	const [location, setLocation] = useState(null);
	const [otherLocation, setOtherLocation] = useState(null);

	const [formData, setFormData] = useState({
		type: TYPES.SCULPTURES,
		email: props.email,
	});
	const [model, setModel] = useState({});

	const [errors, setErrors] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [swiperImages, setSwiperImages] = useState(images.scale);

	const scales = [
		{value: "Hand", label: "Hand"},
		{value: "Body", label: "Body"},
		{value: "Room", label: "Room"}
	];

	const locations = [
		{value: "Lisbon", label: "Lisbon"},
		{value: "North", label: "North"},
		{value: "Center", label: "Center"},
		{value: "South", label: "South"},
		{value: "Other", label: "Other"}
	];

	const handleModelData = (value, field) => {
		setModel({ ...model, [field]: value });
	}

	const submitForm = async () => {
		setErrors([]);
		setIsLoading(true);
		let jsonModel = "";
		if (otherLocation) {
			jsonModel = {...model, "location": otherLocation};
		} else {
			jsonModel = {...model, "location": location};
		}
		const response = await Email.send( {...formData, model: JSON.stringify(jsonModel)});
		if(response.status === 200) {
			props.submit();
			setIsLoading(false);
		}
		else {
			setErrors(response.response.data.reduce(((result, current) => Object.assign(result, current)), {}));
			setIsLoading(false);
		}
	};
	
	return (
		<div className="flex flex-col lg:flex-row items-start justify-between h-full w-full bg-white md:py-10 lg:pb-0">
			<div className="w-full lg:w-3/5 max-h-96 md:max-h-md lg:max-h-3xl xl:max-h-5xl flex flex-col h-full">
				<SwiperCustom images={swiperImages} />
				<p className="py-5 px-5 lg:px-10 xl:px-20 text-2xl xl:text-3xl"></p>
			</div>

			<div className="w-full lg:w-2/5 h-full lg:max-h-full flex flex-col pb-8 md:pb-14 px-5 lg:px-10 xl:pl-10 xl:pr-16 gap-y-6 2xl:gap-y-8 lg:overflow-auto mt-5 md:mt-0">
				
				{/* SCALE */}
				<Accordion label={"Scale"} error={errors?.scale} childrenHasChanges={model.scale} onClick={ () => { setSwiperImages(images.scale) } }>
					<Select options={scales} placeholder={"Scale"} onChange={(e) => { handleModelData(e.value, "scale") }} />
				</Accordion>
				
				{/* MATERIAL */}
				<Accordion label={"Material"} error={errors?.material} childrenHasChanges={model.material} prevAccordionValue={model.scale} >
					<div className="grid grid-cols-3 items-center justify-center w-full gap-y-5">
						{materials.map((material, index) => (
							<WoodCard key={index} wood={material} selectedWood={model.material} onChange={(e) => { handleModelData(e.value, "material") }} />
						))}
					</div>
				</Accordion>

				{/* LOCATION */}
				<Accordion label={"Location"} error={errors?.location} childrenHasChanges={location === "Other" ? null : location} prevAccordionValue={model.material} onClick={ () => { setSwiperImages(images.location) } }>
					<div className="w-full">
						<Select options={locations} placeholder={"Location"} onChange={(e) => { setLocation(e.value); setOtherLocation(null) }} />
						{location === "Other" && (
							<input
								type="text"
								placeholder="Specify Location"
								className="bg-white p-3 xl:p-4 2xl:p-5 w-full rounded-xl text-lg lg:text-xl 2xl:text-2xl shadow-custom focus:outline-none mt-8 lg:mt-5"
								onChange={(e) => setOtherLocation(e.target.value)}
							/>
						)}
					</div>
				</Accordion>
				
				{/* SPECIFICATIONS */}
				<Accordion label={"Specifications"} error={errors?.specifications} prevAccordionValue={location} >
					<div className="w-full mt-5 md:mb-10 flex flex-col gap-y-5">
						<textarea
							onChange={(e) => handleModelData(e.target.value, "specifications")}
							value={model?.specifications}
							className="shadow-custom placeholder:text-black/50 w-full rounded-2xl p-5 placeholder:text-xl text-xl h-52"
							placeholder="Send us a reference..."
						/>
						<div className="flex items-center gap-x-5">
							<label
								htmlFor="file"
								className="text-lg md:text-xl lg:text-2xl flex items-center text-black/30 hover:cursor-pointer"
							>
								<MdOutlineAttachFile />
								<p className="text-black/50 text-lg lg:text-xl ml-2">{formData?.file_image ? formData?.file_image.name : 'Attach your image'}</p>
							</label>
							<input
								onChange={(e) => { setFormData({...formData, "file_image": e.target.files[0]}) }}
								type="file"
								id="file"
								className="hidden"
							/>
						</div>
					</div>
				</Accordion>

				{ /* SUBMIT */ }
				<div className="flex items-center justify-center">
					<SubmitButton isLoading={isLoading} onClick={(e) => {submitForm(e)}} />
				</div>
			</div>
		</div>
	);
};

export default Sculptures;
