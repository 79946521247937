import { useEffect, useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const Accordion = ({ label, error, children, childrenHasChanges = null, prevAccordionValue = null, onClick = null }) => 
{
    const [isSelected, setIsSelected] = useState(false);

    const handleClick = () => {
        setIsSelected(!isSelected);
        if (onClick) {
            onClick();
        }
        // if(!isSelected){
        //     swiper?.slideNext()
        // }
    }

    useEffect(() => {
        if (childrenHasChanges) {
            //setIsSelected(false);
        }
    }, [childrenHasChanges]);

    useEffect(() => {
        if (prevAccordionValue) {
            //setIsSelected(true);
        }
    }, [prevAccordionValue]);

    return (
        <>
        <div className="w-full">
            <div
                onClick={() => { handleClick() }}
                className={`${error ? 'border-b-red-500 text-red-500' : ""} flex items-center justify-between border-b-2 pb-2 xl:pb-3 2xl:pb-5 w-full focus:outline-none cursor-pointer`}
            >
                <h1 className="text-lg lg:text-xl xl:text-2xl 2xl:text-3xl">{error ?? label}</h1>
                <div className="text-xl lg:text-2xl xl:text-2xlxl:text-3xl 2xl:text-4xl">
                    {isSelected ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                </div>
            </div>
            <div className={`${isSelected ? 'flex' : 'hidden'} bg-white text-xl text-black/50 w-full mt-5 focus:outline-none items-center justify-between relative`}>
                {children}
            </div>
        </div>
        </>
    )
}

export default Accordion;