import React, { useEffect, useState } from 'react';
import Logo from '../../assets/Logo.png';
import LogoWhite from '../../assets/Logo-White.png';

const Navbar = (props) => {

    const [logoImage, setLogoImage] = useState(<Logo />);

    useEffect(() => {
        if (props.stateType) {
            setLogoImage(Logo);
        } else {
            setLogoImage(LogoWhite);
        }
    }, [props.stateType])

    return (
        <div className={`${props.stateType ? 'md:bg-white ' : 'md:bg-transparent'} bg-white w-full flex h-full  items-center py-5 md:pt-10 xl:pt-5 md:pb-0 px-5 md:px-10 xl:px-20`}>
            <div className='w-full bg-transparent flex justify-between items-center'>
                <a href='https://www.madeiguincho.pt/'>
                    <img src={logoImage} alt='Madeiguincho' className='hidden md:block md:w-[87px]'/>
                    <img src={Logo} alt='Madeiguincho' className=' md:hidden w-16'/>
                </a>
                {props.stateType ? (
                    <button onClick={() => props.onClick()} type='button' className={`${props.stateType ? 'md:text-black' : 'md:text-white'} text-black underline`}>Go Back</button>
                ) : (
                    <a href='https://www.madeiguincho.pt/' className={`${props.stateType ? 'md:text-black' : 'md:text-white'} text-black underline`}>Go Back</a>
                )}
            </div>
        </div>
    )
}

export default Navbar;