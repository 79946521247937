import React from 'react';
import Modal from '../../assets/Modal.png';

const ModalComp = (props) => {
  return (
    <div className='fixed w-screen h-screen flex items-center justify-center top-0 left-0 z-[999] bg-black/40'>
        <div className='max-w-sm md:w-3/5 md:max-w-5xl relative'>
          <img className='-rotate-90 md:rotate-0' src={Modal} alt='Sculpture' />
          <div className='absolute top-[10%] md:top-1/4 lg:top-1/4 left-1/2 -translate-x-1/2 md:translate-x-0 md:left-[5%] lg:left-[8%] flex flex-col text-white text-center md:text-start'>
            <h1 className='text-lg lg:text-5xl xl:text-6xl'>Thank you for<br/>your request!</h1>
            <p className='lg:text-xl xl:text-2xl mt-2 lg:mt-5'>You can expect to hear back<br />from us shortly.</p>
            <div className='flex flex-col md:flex-row items-center justify-start md:text-lg lg:text-xl mt-2 lg:mt-5 gap-y-2 md:gap-y-0 md:gap-x-5'>
              <button onClick={() => {props.hideModal(); props.backToStart()}} className='border md:border-2 border-white rounded-full px-6 xl:px-10 py-1 lg:py-2'>Build more</button>
              <a href='https://www.madeiguincho.pt/work' className='rounded-full bg-white/30 text-white px-3 xl:px-5 py-1 lg:py-2'>Discover our work</a>
            </div>
          </div>
        </div>
    </div>
  )
}

export default ModalComp