import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const SwiperCustom = (props) => 
{
    return (
        <Swiper
            onInit={(e) => { /*props.getSwiper(e)*/ }}
            modules={[Autoplay, Navigation, Pagination]}
            spaceBetween={0}
            slidesPerView={1}
            navigation
            loop
            pagination={{ clickable: true }}
            autoplay={{ delay: 4000, disableOnInteraction: false }}
            className="w-full h-full"
        >
            {props.images &&
                props.images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img
                            src={image}
                            className="w-full h-full object-contain object-center"
                            alt=""
                        />
                    </SwiperSlide>
                ))}
        </Swiper>
    )
}

export default SwiperCustom;