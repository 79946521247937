import React, { useEffect, useState } from 'react';
import { TfiCheck } from 'react-icons/tfi';
import validator from "validator";
import ContinueButton from '../Form/ContinueButton';
import Input from '../Form/Input';

const InitialForm = (props) => {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const [check, setCheck] = useState(false);
  const [checkClass, setCheckClass] = useState('border border-black/50 h-7 w-7 min-w-[28px] rounded-md flex justify-center items-center mr-5')
  const [termsClass, setTermsClass] = useState('text-black/50 xl:text-lg');

  const [errors, setErrors] = useState([]);

  const validateEmail = () => {
    if (validator.isEmail(email)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (check === true) {
      setCheckClass('border border-green-500/60 h-7 w-7 min-w-[28px] rounded-md flex justify-center items-center mr-5');
      setTermsClass('text-black/50 font-bold xl:text-lg');
    } else {
      setCheckClass('border border-black/50 h-7 w-7 min-w-[28px] rounded-md flex justify-center items-center mr-5');
      setTermsClass('text-black/50 xl:text-lg');
    }
  }, [check]);

  const handleClick = () => {
    if (firstName !== '' && email !== '' && lastName !== '' && check && validateEmail()) {      
      localStorage.setItem('user', JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email: email,
      }));
      props.onInitalForm(firstName, lastName, email);
    } else {
      setErrors({
        firstName: firstName ? false : true,
        lastName: lastName ? false : true,
        email: (email && validateEmail()) ? false : true,
        checkbox: check ? false : true
      });
    }
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setEmail(user.email);
    }
  }, [])
  
  return (
    <>
      <div className='flex flex-col bg-white justify-center items-center px-5 md:px-10 xl:px-0 py-10 xl:pt-5 xl:pb-10 2xl:py-20'>
        <h1 className='text-xl md:text-2xl xl:text-3xl 2xl:text-4xl text-center font-bold text-black'>Before continuing, leave us your contact details.</h1>
        <div className='flex flex-col items-center justify-center max-w-sm md:max-w-md xl:max-w-3xl 2xl:max-w-xl mt-10 xl:mt-5 2xl:mt-10 gap-y-5'>
          <Input value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder={'First Name'} error={errors.firstName} />
          <Input value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder={'Last Name'} error={errors.lastName} />
          <Input value={email} onChange={(e) =>  {setEmail(e.target.value)}} placeholder={'Email'} error={errors.email} />
          <div className='flex items-start mt-5'>
            <button id='checkbox' onClick={() => { check ? setCheck(false) : setCheck(true) }} className={`${checkClass} ${errors.checkbox ? 'border-red-500' : ''}`}>
              {check && <span className='text-green-500/60 text-sm'><TfiCheck /></span>}
            </button>
            <label htmlFor="checkbox" className={`${termsClass} ${errors.checkbox ? 'text-red-500' : ''} cursor-pointer`}>I confirm that I have read and agreed to Madeiguincho's Terms & Conditions and Privacy Policy</label>
          </div>
        </div>
      </div>
      <div className='flex items-center bg-transparent justify-center my-8 lg:my-10 xl:my-0 xl:mt-10 2xl:mt-20'>
        <ContinueButton onClick={() => handleClick()} />
      </div>
    </>
  )
}

export default InitialForm;