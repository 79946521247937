import LiveEdge from './live_edge.webp';
import LiveEdge2 from './live_edge_2.webp';
import RectangleTable from './rectangle_table.webp';
import RectangleLongTable from './rectangle_long_table.webp';
import RectangleLongTable2 from './rectangle_long_table_2.webp';
import RectangleLongTable3 from './rectangle_long_table_3.webp';
import SquareTable from './square_table.webp';
import SquareTable2 from './square_table_2.webp';
import StraightEdge from './straight_edge.webp';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    LiveEdge,
    LiveEdge2,
    RectangleTable,
    RectangleLongTable,
    RectangleLongTable2,
    RectangleLongTable3,
    SquareTable,
    SquareTable2,
    StraightEdge
];